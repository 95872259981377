<template>
  <div style="padding: 5px 5px;min-height: calc(100vh);">
    <v-row v-if="selectedPlan">
      <v-col
        cols="8"
      >
        <list
          v-if="selectedPlan"
          :plan="selectedPlan"
          :locale="locale"
          :onEdit="(v) => { editedItem = JSON.parse(JSON.stringify(v)) }"
          :forceRefresh="forceRefresh"
          :contentTypeID="contentTypeID"
        />
      </v-col>
      <v-col
        cols="4"
      >
        <event-form
          v-if="selectedPlan && editedItem"
          :plan="selectedPlan"
          :value="editedItem"
          :locale="locale"
          :onClose="handleCloseForm"
          :contentTypeID="contentTypeID"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import List from './List'
import EventForm from './Form'
export default {
  name: 'CustomManager',
  components: {
    List,
    EventForm,
  },
  props: {
    contentTypeID: {
      type: String,
      required: true,
    },
    planID: {
      type: String,
      required: true,
    },
    selectedPlan: {
      type: Object,
      requried: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
    forceRefresh: null,
  }),
  mounted () {
    this.editedItem = null
  },
  methods: {
    handleCloseForm (refresh) {
      if (refresh) this.forceRefresh = new Date().toISOString()
      this.editedItem = null
    },
  }
}
</script>

