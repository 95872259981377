<template>
  <div style="background-color: white;">
    <v-data-table
      :headers="headers"
      :items="events"
      :loading="loading"
      :itemsPerPage="itemsPerPage"
      item-key="ID"
      class="page__table"
      hide-default-footer
    >
      <template v-slot:top>
        <custom-table-title 
          :onAdd="handleAdd"
          :title="`Actividades [${plan.id.toUpperCase()}]`"
          icon="mdi-ticket"
          :onSearch="handleSearch"
        />
      </template>
      <template v-slot:[`item.ident`]="{ item }">
        <a @click="() => { handleEdit(item) }">{{ getTranslation(item.Title, locale) || item.ID }}</a>
      </template>
      <template v-slot:[`item.category`]="{ item }">
        {{ plan.category ? plan.category.filter(x => x.id === item.CategoryID).map(item => item.name.es).shift() : '' }}
      </template>
      <template v-slot:[`item.subcategory`]="{ item }">
        {{ plan.subcategory ? plan.subcategory.filter(x => x.id === item.SubcategoryID).map(item => item.name.es).shift() : '' }}
      </template>
      <template v-slot:[`item.weekday`]="{ item }">
        {{ plan.weekday ? plan.weekday.filter(x => x.id === item.WeekdayID).map(item => item.name.es).shift() : '' }}
      </template>
      <!--template v-slot:body="props">

      </template-->
    </v-data-table>
    <pagination
      :startIndex="startIndex"
      :itemsPerPage="itemsPerPage"
      :count="count"
      :onGetData="handleGetData"
      :locale="locale"
    />
  </div>
</template>
<script>
const defaultItem = {
  Title: { es: '' },
  Place: { es: '' },
  Description: { es: '' },
  Reservable: false,
  Pax: null,
  PVPHosted: null,
  PVPNoHosted: null
}
import api from '@/services/api'
import utilsService from '@/services/utils'
import CustomTableTitle from '@/components/customTable/CustomTableTitle'
import Pagination from '@/components/customTable/Pagination'
export default {
  components: {
    CustomTableTitle,
    Pagination,
  },
  props: {
    contentTypeID: {
      type: String,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onEdit: {
      type: Function,
      requried: true,
    },
    forceRefresh: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    search: '',
    startIndex: 0,
    itemsPerPage: 10,
    events: [],
    count: null,
  }),
  computed: {
    headers () {
      return [
        { text: 'Actividad', value: 'ident',  sortable: false, align: 'left', },
        { text: 'Categoría', value: 'category',  sortable: false, align: 'left', },
        { text: 'Subcategoría', value: 'subcategory',  sortable: false, align: 'left', },
        { text: 'Weekday', value: 'weekday',  sortable: false, align: 'left', },
      ]
    },
  },
  watch: {
    'plan.id' () {
      this.handleGetData()
    },
    forceRefresh () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getTranslation (v, locale) {
      return utilsService.getTranslation(v, locale)
    },
    handleSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleGetData (startIndex) {
      if (startIndex >= 0 ) this.startIndex = startIndex
      const url = `v1/private/activity-barcelo-01/custom-manager/${this.contentTypeID}/${this.plan.id}/`
      api.getAll ('dviewcontent', url, this.startIndex, this.itemsPerPage, this.search)
        .then(response => {
          if (!response) {
            this.loading = false
            return
          }
          this.count = response.count
          this.events = response.data.map(item => {
            item.Title = item.Title ? JSON.parse(item.Title) : { es: '' }
            item.Place = item.Place ? JSON.parse(item.Place) : { es: '' }
            item.Description = item.Description ? JSON.parse(item.Description) : { es: '' }
            return item
          })
          this.loading = false
        })
    },
    handleAdd () {
      const aux = defaultItem
      aux.ContentTypeID = this.contentTypeID
      aux.PlanID = this.plan.id
      aux.CategoryID = this.plan.category && this.plan.category.length > 0 ? this.plan.category.map(item => item.id).shift() : null
      aux.SubcategoryID = this.plan.subcategory && this.plan.subcategory.length > 0 ? this.plan.subcategory.map(item => item.id).shift() : null
      aux.WeekdayID = this.plan.weekday && this.plan.weekday.length > 1 ? this.plan.weekday.map(item => item.id)[1] : null
      this.onEdit(aux)
    },
    handleEdit (v) {
      this.onEdit(v)
    },
  },
}
</script>

